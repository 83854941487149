import React from "react";
import styles from "./support.module.scss";

import { ReactComponent as USDT } from "@assets/svg/coins/USDT.svg";
import { ReactComponent as ETH } from "@assets/svg/coins/ETH.svg";
import { ReactComponent as USDC } from "@assets/svg/coins/USDC.svg";
import { ReactComponent as ISLM } from "@assets/svg/coins/ISLM.svg";
import { ReactComponent as BNB } from "@assets/svg/coins/BNB.svg";
import { ReactComponent as POL } from "@assets/svg/coins/MATIC.svg";
import { ReactComponent as ARB } from "@assets/svg/coins/ARB.svg";
import { ReactComponent as BASE } from "@assets/svg/coins/BASE.svg";
import { ReactComponent as FTM } from "@assets/svg/coins/FTM.svg";
import { ReactComponent as MNT } from "@assets/svg/coins/MNT.svg";
import { ReactComponent as AVAX } from "@assets/svg/coins/AVAX.svg";

export const SupportNetworks = () => {
  const icons = [
    ETH,
    ISLM,
    BNB,
    POL,
    ARB,
    BASE,
    FTM,
    AVAX,
    MNT,
    USDT,
    USDC,
    // Add other imported icons here...
  ];

  return (
    <article className={styles.iconsContainer}>
      <div className={styles["icon-container"]}>
        {icons.map((IconComponent, index) => (
          <div key={index} className={styles["icon-wrapper"]}>
            <IconComponent />
          </div>
        ))}
      </div>
      <p className={styles.supportText}>Supported EVM networks</p>
    </article>
  );
};
